import React from "react"
import { 
  graphql,   
  Link as GatsbyLink
} from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"

import { 
  Box,
  Container, 
  Flex, 
  Heading,
  Link,
  Text,
} from "@chakra-ui/react"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Providers from "../components/providers"

const PageTemplate = ({ data: { previous, next, post } }) => {

  /*
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }
  */

  return (
    <Providers>
      <Layout>
        <SEO title={post.title} description={post.excerpt} />
        <article
          className="page-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Container maxW="container.md">
            <Flex as="header" flexDirection="column" paddingTop="32">
              <Heading as="h1" size="2xl" itemProp="headline">{parse(post.title)}</Heading>
              <Text mt="2">{post.date}</Text>
              <hr />
            </Flex>
          </Container>
          <Container maxW="container.md">
            {!!post.content && (
              <Box as="section" className="page-content" itemProp="articleBody">{parse(post.content)}</Box>
            )}          
          </Container>
        </article>
        <Container maxW="container.md">
          <hr />
          <nav className="blog-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link as={GatsbyLink} to={previous.uri} rel="prev">
                    ← {parse(previous.title)}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link as={GatsbyLink} to={next.uri} rel="next">
                    {parse(next.title)} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          <hr />
        </Container>
      </Layout>
    </Providers>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
